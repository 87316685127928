<template>
  <div class="container">
    <FilterComponent :tags="tags" @filter="applyFilter" @search="applySearch" />
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 mb-3" v-for="item in paginatedItems" :key="item.id">
        <ItemComponent :item="item" />
      </div>
    </div>
    <PaginationComponent
      :currentPage="currentPage"
      :totalPages="totalPages"
      @page-changed="changePage"
    />
  </div>
</template>

<script>
import axios from 'axios';
import ItemComponent from '../components/ItemComponent.vue';
import FilterComponent from '../components/FilterComponent.vue';
import PaginationComponent from '../components/PaginationComponent.vue';

export default {
  components: {
    ItemComponent,
    FilterComponent,
    PaginationComponent
  },
  data() {
    return {
      items: [],
      filteredItems: [],
      tags: ['Küche', 'Technik', 'Beauty', 'Bücher', 'DIY', 'Kaffee', 'Wohnung', 'Spielzeug', 'Unterwegs'],
      currentPage: 1,
      itemsPerPage: 8,
      searchQuery: '',
      selectedTags: []
    }
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    }
  },
  created() {
    axios.get('/items.json').then(response => {
      this.items = response.data;
      this.filteredItems = this.items;
    });
  },
  methods: {
    applyFilter(selectedTags) {
      this.selectedTags = selectedTags;
      this.filterItems();
      this.currentPage = 1;
    },
    applySearch(query) {
      this.searchQuery = query.toLowerCase();
      this.filterItems();
      this.currentPage = 1;
    },
    filterItems() {
      let filtered = this.items;
      
      // Apply tag filtering
      if (this.selectedTags.length > 0) {
        filtered = filtered.filter(item => {
          return this.selectedTags.some(tag => item.tags.includes(tag));
        });
      }

      // Apply text search filtering
      if (this.searchQuery) {
        filtered = filtered.filter(item => {
          return item.title.toLowerCase().includes(this.searchQuery) ||
                 item.description.toLowerCase().includes(this.searchQuery);
        });
      }

      this.filteredItems = filtered;
    },
    changePage(page) {
      this.currentPage = page;
    }
  }
}
</script>

<style scoped>
/* Add any additional styles here */
</style>