<template>
<div class="filter-container">
    <input
      type="text"
      class="form-control search-input"
      placeholder="Suche..."
      v-model="searchQuery"
      @input="emitSearchQuery"
    />
    <div class="filter-buttons">
      <button
        v-for="tag in tags"
        :key="tag"
        @click="toggleFilter(tag)"
        :class="['btn', 'btn-outline-custom', { 'active': selectedTags.includes(tag) }]"
      >
        {{ tag }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tags: Array
  },
  data() {
    return {
      selectedTags: [],
      searchQuery: ''
    }
  },
  methods: {
    toggleFilter(tag) {
      const index = this.selectedTags.indexOf(tag);
      if (index >= 0) {
        this.selectedTags.splice(index, 1);
      } else {
        this.selectedTags.push(tag);
      }
      this.$emit('filter', this.selectedTags);
    },
    emitSearchQuery() {
      this.$emit('search', this.searchQuery);
    }
  }
}
</script>

<style scoped>
  .search-input {
    margin-top: 5px;
    border-color: #d2b48c;
    color: #000;
  }
  .search-input:focus {
    border-color: #d2b48c;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
</style>