<template>
    <div v-if="visible" class="no-cookies-indicator">
      <span>Es werden keine Cookies gesetzt :)</span>
      <button class="close-button" @click="closeIndicator">x</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NoCookiesIndicator',
    data() {
      return {
        visible: true
      };
    },
    methods: {
      closeIndicator() {
        this.visible = false;
      }
    }
  }
  </script>
  
  <style scoped>
  .no-cookies-indicator {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 200px; /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
    background-color: rgba(0, 0, 0, 0.7); /* Adjust background color as needed */
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Adjust to space-between for close button */
    border-radius: 25px; /* For a circular look, adjust radius */
    font-size: 12px; /* Adjust font size as needed */
    text-align: center;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure it appears on top of other elements */
  }
  
  .close-button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: 0 10px; /* Adjust padding to position close button */
  }
  </style>