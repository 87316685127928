import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import DatenschutzPage from '../views/DatenschutzPage.vue'
import ImpressumPage from '../views/ImpressumPage.vue'
import AffiliatePage from '../views/AffiliatePage.vue'

const routes = [
  { path: '/', component: HomePage },
  { path: '/datenschutz', component: DatenschutzPage },
  { path: '/impressum', component: ImpressumPage },
  { path: '/affiliate', component: AffiliatePage }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router