<template>
  <div class="container mt-4">
    <h1>Was ist Affiliate Marketing?</h1> <br>
    <p>Im Folgenden wollen wir darüber informieren was Affiliate Marketing ist und wie wir es auf forya.de nutzen.</p> <br>

<strong>Wie nutzen wir Affliate Marketing?</strong> <br>

<p>Wir nutzen Amazon.de Partnerlinks (Affiliate Links) für Affiliate Marketing. Beim Amazon Affiliate Marketing geht es darum Produkte von Amazon in z. B. Videos zu zeigen. Die Videos enthalten einen Link zu einer Webseite z.B forya.de welche Partnerlinks zu Amazon enthält.</p> <br>

<strong>Im Folgenden wird der Vorgang der Vermarktung beschrieben:</strong> <br>

<p>Sobald ein Nutzer auf forya.de auf einen Partnerlink zu einem Artikel (Bild, Titel oder Button "Link zu Amazon") in unseren Produktsammlungen klickt öffnet sich die zugehörige Amazon Seite mit dem Produkt auf das der Nutzer geklickt hat.</p> <br>
<p>Kauft der Nutzer nun über diesen Link das zuvor angesehene Produkt oder ein anderes Produkt innerhalb von einer von Amazon festgelegten Frist (24 Stunden) bekommen die Inhaber der forya.de Seite eine von Amazon festgelegte Vergütung die je nach Produktkategorie unterschiedlich ist.</p> <br>

<p>Wir vermarkten Amazon Produkte unter anderem mit Videos auf TikTok und YouTube. Dabei handelt es sich immer um Werbung.</p> <br>

<p> Als Amazon-Partner verdienen wir an qualifizierten Verkäufen.</p>
  </div>
</template>

<script>
export default {
  name: 'AffiliatePage'
}
</script>

<style scoped>
.container {
  max-width: 800px;
}
</style>