<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light navbar-custom">
      <div class="container-fluid justify-content-between">
        <router-link class="navbar-brand" to="/">forya.de</router-link>
        <router-link class="navbar-text link-underline link-underline-opacity-0" to="/affiliate">Affiliate Marketing</router-link>
      </div>
    </nav>
    
    <router-view></router-view>

    <footer class="footer-custom">
      <div class="container text-center">
        <p>2024 von forya.de</p>
        <div class="footer-links d-flex flex-column flex-md-row justify-content-center">
          <router-link to="/impressum" class="footer-link">Impressum</router-link>
          <router-link to="/datenschutz" class="footer-link">Datenschutzerklärung</router-link>
          <router-link to="/affiliate" class="footer-link">Was ist Affiliate Marketing?</router-link>
        </div>
      </div>
    </footer>
    
    <!-- Cookie Banner -->
   <!-- <CookieBanner />-->
    <NoCookiesIndicator />
  </div>
</template>

<script>
//import CookieBanner from './components/CookieBanner.vue';
import NoCookiesIndicator from './components/NoCookiesIndicator.vue';


export default {
  name: 'App',
  components: {
    //CookieBanner
    NoCookiesIndicator
  }
}
</script>

<style>
@import './assets/custom.css';

.navbar-custom .navbar-text {
  font-size: 1rem;
  color: #000;
}

.footer-custom {
  background-color: #f5f5f0;
  padding: 20px 0;
  border-top: 1px solid #e5e5e5;
}

.footer-links {
  display: flex;
  flex-direction: column; /* Stack vertically on mobile */
}

.footer-link {
  color: #000;
  margin: 5px 0; /* Add margin for spacing between links */
}

@media (min-width: 768px) {
  .footer-links {
    flex-direction: row; /* Stack horizontally on larger screens */
  }

  .footer-link {
    margin: 0 10px; /* Add horizontal margin for spacing between links */
  }
}

.footer-link:hover {
  text-decoration: underline;
}

#app {
  position: relative;
  z-index: 1; /* Ensure the app content is displayed behind the banner and modal */
}
</style>