import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.css'
import './assets/custom.css'

// Scroll to top on route change
router.afterEach(() => {
  window.scrollTo(0, 0);
});

createApp(App).use(router).mount('#app')